import React from  "react";
import "./contactcomponent.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Socials from "../Socials";
import {faGlobeAmericas, faStar,faClock} from "@fortawesome/free-solid-svg-icons";

const ContactComponent = () =>{

    return (
        <div className="contact-main-container">
   

            <section id="formulaire">
                <h1>Get in touch</h1>
                <hr />
                <form name="Form1" action="http://www.iro.umontreal.ca/~lapalme/ift1005/Exercices/9/echoValeurs.cgi" method="POST" encType="multipart/form-data">
                    <div className="first-line">
                        <div>
                            <label>Your Name (required)<br />
                                <span>
                                    <input type="text" name="your-name" size="80" required />
                                </span>
                            </label>
                        </div>
                        <div>
                            <label>Your Email (required)<br />
                                <span>
                                    <input type="email" name="your-email" size="80" required />
                                </span>
                            </label>
                        </div>
                        <div>
                            <label>Phone<br />
                                <span>
                                    <input type="tel" pattern="(\+389)[\-]\d{3}[\-]\d{3}" name="your-phone" size="80" />
                                </span>
                            </label>
                        </div>
                    </div>
                
                    <div className="third_line">
                        <label>Your Message<br />
                            <span>
                                <textarea name="your-message" cols="240" rows="4"></textarea>
                            </span>
                        </label>
                    </div>
                    <button className="button button1" type="submit" name="send">Send</button>
                </form>
            </section>
            <Socials />
        </div>
    );
}



export default ContactComponent;