import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faTiktok, faYoutube} from "@fortawesome/free-brands-svg-icons";

import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import './socials.css';

export default class Socials extends React.Component {
    render() {
        return <section className="socials-container">
            <a href="https://youtube.com/@ondzaessey"> <FontAwesomeIcon icon={faYoutube}></FontAwesomeIcon> <span>Youtube</span></a>
            <a href="https://instagram.com/ondzaessey"> <InstagramIcon/> <span>Instagram</span> </a>
            <a href="mailto:ondza7@icloud.com"><MailOutlineIcon/> <span>Email</span>  </a>
        </section>;
    }
}