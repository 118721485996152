import React, {createContext, useState} from 'react'
import { createTheme } from '@mui/material/styles';


export const luxuriousTheme = createTheme({
    palette: {
      primary: {
        main: '#000', // Dark color for a luxurious feel
      },
      secondary: {
        main: '#b2a57d', // Gold color for accents
      },
    },
    typography: {
      fontFamily: '"Playfair Display", serif', // For headings
      body1: {
        fontFamily: '"Montserrat", sans-serif', // For body text
      },
    },
    components: {
      // Your existing overrides plus any new ones
      MuiTextField: {
        // ... your existing styles
      },
      // You can add overrides for other components that you want to style luxuriously
      MuiButton: {
        styleOverrides: {
          root: {
            // Example of a luxurious button style
            fontWeight: 'bold',
            letterSpacing: '0.1rem',
            transition: '0.3s ease-in-out',
            '&:hover': {
              transform: 'scale(1.05)',
            },
          },
        },
      },
      // Add more component overrides as needed
    },
  });
