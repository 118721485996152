import React, {useState} from 'react';
import "react-datepicker/dist/react-datepicker.css"
import "./reservationForm.css"
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';

import {
    TextField,
    Button,
    Typography,
    Container,
    Grid,
    Box,
    FormControlLabel,
    Checkbox,
    MenuItem,
    Select, CircularProgress
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from "axios";
let apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3000/api";


const validationSchema = yup.object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email('Enter a valid email').required('Email is required'),
    phone: yup.string().matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, 'Enter a valid phone number').required('Phone number is required'),
    dateOfEvent: yup.date().required('Date of event is required'),
    numberOfGuests: yup.number().typeError('Enter a valid number').min(1, 'At least one guest is required').required('Number of guests is required'),
    additionalInfo: yup.string(),
    agreeTerms: yup.boolean().oneOf([true], 'You must agree to the terms and conditions'),
});

const ReservationForm = (props) => {

    const { register, handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });


    const [date, setDate] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);

    function onChange(date) {
        setDate(date);
    }

    const mapToBackendDTO = (formData) => {
        return {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            phone: formData.phone,
            message: formData.additionalInfo,
            reservationType: props.reservationType,
            dateOfRequest: new Date().toISOString(),
            dateOfEvent: new Date().toISOString(),
            numberOfGuests: parseInt(formData.numberOfGuests),
        };
    };

    const handleFocus = (event) => {
        const target = event.target;
        const defaultValue = target.defaultValue;


        if(target.value === defaultValue)
            target.value = '';
    }

    const onSubmit = async (data) => {
        try{
            setIsSubmitting(true);

            const backendData = mapToBackendDTO(data);

            const response = await axios.post(`${apiUrl}/reservations/create`, backendData, {
                headers: {
                    'Content-Type': 'application/json',
                }});
            if(response.status === 200) {
                setSubmitSuccess(true);
                console.log('Reservation created:', response.data);

            }
        }catch (error){
            console.error("Error submitting form", error.response || error);
        }finally {
            setIsSubmitting(false);
        }
    };
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        dateOfEvent: null,
        numberOfGuests: '',
        additionalInfo: '',
        agreeTerms: false,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleDateChange = (date) => {
        setFormData({ ...formData, dateOfEvent: date });
    };



    return (
        <Container component="main" maxWidth="xs">
            <Typography component="h1" variant="h5">
                Reservation Form
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} className="slide-up">
                        <TextField
                            {...register("firstName")}
                            label="First Name"
                            required
                            fullWidth
                            error={!!errors.firstName}
                            helperText={errors.firstName?.message}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className="slide-up">
                        <TextField
                            {...register("lastName")}
                            label="Last Name"
                            required
                            fullWidth
                            error={!!errors.lastName}
                            helperText={errors.lastName?.message}
                        />
                    </Grid>
                    <Grid item xs={12} className="slide-up">
                        <TextField
                            {...register("email")}
                            label="Email Address"
                            required
                            fullWidth
                            error={!!errors.email}
                            helperText={errors.email?.message}
                        />
                    </Grid>
                    <Grid item xs={12} className="slide-up">
                        <TextField
                            {...register("phone")}
                            label="Phone Number"
                            required
                            fullWidth
                            error={!!errors.phone}
                            helperText={errors.phone?.message}
                        />
                    </Grid>
                    <Grid item xs={12} className="slide-up">
                        <Controller
                            name="dateOfEvent"
                            control={control}
                            render={({ field }) => (
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        {...field}
                                        label="Date of Event"
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                error={!!errors.dateOfEvent}
                                                helperText={errors.dateOfEvent?.message}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} className="slide-up">
                        <TextField
                            {...register("numberOfGuests")}
                            label="Number of Guests"
                            type="number"
                            required
                            fullWidth
                            error={!!errors.numberOfGuests}
                            helperText={errors.numberOfGuests?.message}
                            InputProps={{ inputProps: { min: 1 } }}
                        />
                    </Grid>
                    <Grid
                    className="slide-up"
                     item xs={12}>
                        <TextField
                            {...register("additionalInfo")}
                            label="Additional Information"
                            multiline
                            rows={4}
                            fullWidth
                        />
                    </Grid>
                    <Grid 
                    className="slide-up"
                    item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    {...register("agreeTerms")}
                                    error={!!errors.agreeTerms}
                                />
                            }
                            label="I agree to the Terms and Conditions"
                            sx={ {
                                color: '#00091a'
                            }}
                        />
                        {errors.agreeTerms && <Typography color="error">{errors.agreeTerms.message}</Typography>}
                    </Grid>
                </Grid>
                <Button
                className="slide-up"
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={isSubmitting}
                    sx={{ mt: 3, mb: 2, background: 'rgb(252, 82, 31)' }}
                >
                    {isSubmitting ? <CircularProgress size={24} /> : 'Submit'}
                </Button>
            </Box>
        </Container>
    );
};

export default ReservationForm;
