import * as React from 'react';
import {useEffect} from 'react';
import Socials from '../../components/Socials';
import './home.css';
import CategorySquareCard from "../../components/Category/CategorySquareCard";
 import tailoredChef1 from '../../assets/Portrait/IMG_2272.jpeg';
 import tailoredChef2 from '../../assets/Events/IMG_4133.jpeg';
 import tailoredChef3 from '../../assets/Events/IMG_0683.jpeg';
 import exclusiveDinings1 from '../../assets/Portrait/Kaos-6.jpg';


 import exclusiveDinings2 from '../../assets/Portrait/2A1852BA-FB55-40DB-A72B-1E4266E0880A.jpeg';
 import exclusiveDinings3 from '../../assets/Portrait/Kaos-70.jpg';
 import culinaryMasterclass1 from '../../assets/Blades/IMG_1738.jpeg';
 import culinaryMasterclass2 from '../../assets/main-pic.jpeg';
 import culinaryMasterclass3 from '../../assets/plating-2.jpeg';
 import culinaryMasterclass4 from '../../assets/IMG_1300.jpg';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { ThemeProvider, Container, Typography, Box, Grid } from '@mui/material';
import {luxuriousTheme} from '../../context/theme';

export default function Home() {
    const currentYear = new Date().getFullYear();
    const activities = [
        {
            id: 1,
            title: 'Culinary Masterclasses',
            description: 'These workshops are designed to provide hands-on learning opportunities for individuals interested in mastering the art of African or International cuisine and pastry. Participants will have the chance to learn traditional techniques, explore unique flavors, and gain a deeper understanding of the cultural significance behind these culinary practices.',
            images:{
                img1: culinaryMasterclass4,
                img2: culinaryMasterclass1,
                img3: culinaryMasterclass2,
                img4: culinaryMasterclass3
            },
            category: 'culinary_mc',
            reservationType: 'CULINARY_MASTERCLASSES'
        },
        {
            id: 2,
            title: 'Exclusive Dinings',
            description: 'We offer exclusive private dining experiences, allowing clients to indulge in the flavors and elegance of African cuisine in an intimate setting. These private dinners are meticulously crafted to showcase the artistry and gastronomic excellence of our culinary team.',
            images: {
                img1: exclusiveDinings1,
                img2: exclusiveDinings2,
                img3: exclusiveDinings3
            },
            category: "exclusive_dinings",
            reservationType: 'EXCLUSIVE_DININGS'
        },
        {
            id: 3,
            title: 'Tailored Chef Services',
            description: 'Our skilled and experienced team are available to cater to private events, whether they are hosted at a client\'s home or a chosen venue. We provide personalized menus that highlight the rich diversity of African cuisine and pastry, creating memorable dining experiences for our customers and their guests.',
            images: {
                img1: tailoredChef1,
                img2: tailoredChef2,
                img3: tailoredChef3
            },
            category: 'tailored_cs',
            reservationType: 'TAILORED_CHEF_SERVICES'

        },
    ];


    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    return (

        <ThemeProvider theme={luxuriousTheme}>
            <Container maxWidth="lg" className="home-container">
                <Box my={4} >
                    <Typography variant="h2" gutterBottom className="brand-title" sx={{ display: { xs: 'block', md: 'none' }, color: 'black'}} >
                        ONDZA ESSEY
                    </Typography>
                    <Typography variant="h5" className="brand-subtitle">
                        Welcome to the future of an organic cuisine.
                    </Typography>
                </Box>

                <Grid container spacing={4}>
                    {activities.map((activity, index) => (
                        <Grid item xs={12} sm={6} md={4} key={activity.id}>
                            <CategorySquareCard
                                title={activity.title}
                                description={activity.description}
                                images={activity.images}
                                category={activity.category}
                                reservationType={activity.reservationType}
                            />
                        </Grid>
                    ))}
                </Grid>
                <Socials/>
                <Box textAlign="center" py={4}>
                    <Typography variant="body2" color="text.secondary">
                        &copy; {currentYear} Ondza Essey. All Rights Reserved.
                    </Typography>
                </Box>
            </Container>
        </ThemeProvider>
    );

}


