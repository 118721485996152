import React, {useState, useEffect} from 'react';
import "./categorySquareCard.css"
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import 'primeicons/primeicons.css';
import {Card, CardActionArea, CardContent, CardMedia, Grid, Typography} from "@mui/material";

const cardStyle = {
    width: 345, // Fixed width
    height: 400, // Fixed total height
    margin: 'auto',
    marginBottom: 2,
    boxShadow: '0px 3px 15px rgba(0,0,0,0.2)', // Optional shadow effect
};
const CategorySquareCard = (props) => {

    const navigate = useNavigate();

    function openActivityPage() {
        navigate(`/activities/${props.category}`, {
            state: {
                title: props.title,
                description: props.description,
                images: props.images,
                reservationType: props.reservationType
            }
        });

    }

    return (
            <Card sx={cardStyle} className={`square-card-main category-card-enter`}>
                <CardActionArea onClick={openActivityPage}>
                    <CardMedia
                        component="img"
                        image={props.images.img1}
                        alt={props.title}
                        sx={{
                            height: 350,
                            objectFit: 'cover'
                        }}
                    />
                    <CardContent sx={{ height: 50 , background: 'rgb(208,109,50)'}}> {/* Fixed content height */}
                        <Typography gutterBottom variant="h5" component="div" sx={{ textAlign: 'center', fontFamily: 'f37bergman', color: 'white' }}>
                            {props.title}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
    );

}

export default CategorySquareCard;