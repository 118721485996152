import React, {useContext, useState, useRef, useEffect} from 'react'
import {Box, Button, Container, Grid, Typography} from "@mui/material";
import ReservationForm from "../Contact/ReservationForm";
import {useLocation, useNavigate} from 'react-router-dom';
import { ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon, ArrowBackIos as ArrowBackIosIcon } from '@mui/icons-material';
import './ActivityPage.css'
import {ThemeContext} from "@emotion/react";


const ActivityPage = (props) => {
    const location = useLocation();
    const { title, description, images, reservationType } = location.state || {};
    const navigate = useNavigate();
    const { theme } = useContext(ThemeContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const scrollContainerRef = useRef(null);


    const goBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
        }
    };

    return(
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
                            <Button
                                onClick={goBack}
                                sx={{
                                    position: 'absolute',
                                    top: 40,
                                    left: 20,
                                    zIndex: 1000,
                                    color: '#090c25'}}
                            >
                                <ArrowBackIcon sx={{ mr: 1, color: '#090c25' }} />
                                Back
                            </Button>
                            <Typography className="slide-down" variant="h4" component="h1" sx={{ flexGrow: 1 , color: 'text.primary' }}>
                                {props.title}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid className="slide-down" item xs={12} md={6} >
                        {/* Scrollable image container */}
                        <Box
                            sx={{
                                '& img': {
                                    width: '100%',
                                    height: 'auto',
                                },
                                '@media (max-width: 768px)': {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    overflowX: 'auto',
                                    overflowY: 'hidden',
                                    alignItems: 'center'
                                }
                            }}
                        >
                            <img src={images.img1} alt="activity image 1" style={{ width: '100%', display: 'block' }} />                        
                            <img src={images.img2} alt="activity image 1" style={{ width: '100%', display: 'block' }} />                        
                            <img src={images.img3} alt="activity image 1" style={{ width: '100%', display: 'block' }} />                    
                        </Box>
                        {isMobile && (
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                            <Button onClick={scrollLeft}>
                                <ArrowBackIosIcon />
                            </Button>
                            <Button onClick={scrollRight}>
                                <ArrowForwardIcon />
                            </Button>
                        </Box>
                    )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography className="slide-down" variant="h4" gutterBottom sx={{  color: 'text.primary'  }}>
                            {title}
                        </Typography>
                        <hr style={{height:"2px", borderWidth:"0",color:"#F07B0B", backgroundColor:"#F07B0B"}}/>
                        <Typography className="slide-down" variant="h5" gutterBottom sx={{ fontFamily: 'f37bergman', color: 'text.primary'  }}>
                            {description}
                        </Typography>


                        <ReservationForm

                            reservationType={reservationType}/>
                    </Grid>
                </Grid>
            </Container>
    );

}

export default ActivityPage;