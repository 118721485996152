import React from 'react';
import { Drawer, List, ListItem, ListItemText, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';



const DrawerComponent = ({ open, onClose }) => {
    const navigate = useNavigate();

    const handleNavigate = (path) => {
        onClose();
        navigate(path);
    };

    return (
        <Drawer anchor="right" open={open} onClose={onClose}>
            <IconButton onClick={onClose} sx={{ alignSelf: 'flex-end', m: 1 }}>
                <CloseIcon />
            </IconButton>
            <List>
                <ListItem button onClick={() => handleNavigate('/')}>
                    <ListItemText primary="Home" />
                </ListItem>
                <ListItem button onClick={() => handleNavigate('/about')}>
                    <ListItemText primary="About" />
                </ListItem>
                <ListItem button onClick={() => handleNavigate('/services')}>
                    <ListItemText primary="Services" />
                </ListItem>
                <ListItem button onClick={() => handleNavigate('/contact')}>
                    <ListItemText primary="Contact" />
                </ListItem>
            </List>
        </Drawer>
    );
};

export default DrawerComponent;
