import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import About from "./components/About/About";
import Home from "./routes/Home/Home";
import ContactComponent from "../src/components/Contact/ContactComponent";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import React, {useState} from 'react';
import ActivityPage from "./components/ActivityPage/ActivityPage";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {luxuriousTheme } from '../src/context/theme';

export default function App() {
  return (
    <ThemeProvider theme={luxuriousTheme}>
      <BrowserRouter>
        <div className="App">
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />

            <Route path="/contact" element={<ContactComponent />} />
            <Route path="/activities/:category" element={<ActivityPage />} />
          </Routes>
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}