import "./about.css";
import React, { Component }  from 'react';
import Socials from "../Socials";
import { useEffect } from "react";
import { Typography, Box, Container, Button } from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
export default function About() {
        useEffect(() => {
        if (document) {
            const stylesheet = document.createElement("link");
            stylesheet.rel = "stylesheet";
            stylesheet.href = "https://unpkg.com/tailwindcss@^2/dist/taitmtc" +
                "lwind.min.css";

            document.head.appendChild(stylesheet);
        }
    }, []);

  const goBack = () => {
        navigate(-1);
    };
    return (
        <Container className="container">
            <Button
              onClick={goBack}
              sx={{
                position: 'absolute',
                top: 40,
                left: 20,
                zIndex: 1000,
                color: '#090c25'}}
            >
              <ArrowBackIcon sx={{ mr: 1, color: '#090c25' }} />
              Back
            </Button>
                  <Container className="div1">
                      <img
                          id="portrait-1"
                          src={require("../../assets/portrait-2.jpeg")}
                          alt="portrait-1.png"
                          loading="lazy"
                      ></img>
                  </Container>
                  <Box className="div2">
                      <h1 style={{ fontSize: "2rem", color:"#F07B0B"}}>What HOSCA is about</h1>
                      <hr style={{height:"2px", borderWidth:"0",color:"#F07B0B", backgroundColor:"#F07B0B"}}/>

                      <Typography>
                      An African based culinary ecosystem. Done through private dinning and workshops 
                      </Typography>
                  </Box>
                  <Box className="div3">
                      <img
                          id="portrait-1"
                          src={require("../../assets/portrait-3.jpeg")}
                          alt="portrait-1.png"
                          loading="lazy"
                      ></img>
                  </Box>
                  <Container className="div4">
                      <Typography>
                      In terms of marketing and sales strategies, Ondza employs a multi-faceted approach to reach and engage with our target audience. Our primary focus is on building a growing social media presence and fostering meaningful engagement with our followers. Through strategic content creation, captivating visuals, and compelling storytelling, we aim to cultivate a strong online community that resonates with our brand and culinary offerings.
                      Furthermore, we are dedicated to earning the respect that African cuisine and its cultural heritage deserve. By highlighting the historical significance and stories behind the dishes, we aim to dismantle misconceptions and showcase the depth and sophistication of African culinary traditions. 
                      </Typography>

                    <Box>
                      <Typography>
                        Ondza Essey, formerly known as Ondza Bongise, derives its name from the Téké language,
                        a traditonal language spoken in the Anziku Kingdom, also known as the Téké Kingdom, which
                        was in what is now Congo. "Ondza" translates to "eat well" or "good food" in Téké. This linguistic
                        choice reflects the founder's deep connec9on to their roots and historical background.
                        Established in 2022, Ondza Essey is a company dedicated to providing a culinary experience that
                        showcases both Congolese cuisine and broader African cuisine. The company's vision is to
                        introduce customers to the rich and diverse flavors of African gastronomy while incorporating
                        selected and specific African influences.


                        - At Ondza Essey, we take pride in our dedication to culinary excellence, cultural authenticity, and customer satisfaction. By bringing the essence of African cuisine and pastry to the forefront, we strive to create unforgettable culinary journeys that celebrate the richness of our culinary heritage.
                        — These experiences are delivered through a range of services that fall within our realm of prominent expertise.
                      </Typography>
                    </Box>

                  </Container>


              <Socials />

          </Container>
  );
}
